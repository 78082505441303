import { useScopedState } from '~/lib/frontend/common/composables/scopedState'
import {
  type AbstractErrorHandler,
  type AbstractUnhandledErrorHandler
} from '~/lib/frontend/core/helpers/observability'

export type ErrorLoggingTransport = {
  onError: AbstractErrorHandler
  onUnhandledError?: AbstractUnhandledErrorHandler
}

const useErrorLoggingTransportState = () =>
  useScopedState('useErrorLoggingTransport', () => ({
    transports: [] as ErrorLoggingTransport[]
  }))

export const useCreateErrorLoggingTransport = () => {
  const { transports } = useErrorLoggingTransportState()

  return (transport: ErrorLoggingTransport) => {
    transports.push(transport)
    const remove = () => {
      const idx = transports.indexOf(transport)
      if (idx !== -1) {
        transports.splice(idx, 1)
      }
    }

    return remove
  }
}

export const useGetErrorLoggingTransports = () => {
  const { transports } = useErrorLoggingTransportState()
  return transports
}
