import { useGetApiErrorMessage } from '~/lib/frontend/common/composables/fetch'
import { useLogger } from '~/lib/frontend/common/composables/logger'
import {
  ToastNotificationType,
  useGlobalToast
} from '~/lib/frontend/common/composables/toast'
import {
  useCreateFunction,
  useGetCreationMetadata,
  useGetFunctions,
  usePatchFunction
} from '~/lib/frontend/functions/composables/management'

export const useFunctionsStore = defineStore('functions', () => {
  const resolveErrMessage = useGetApiErrorMessage()
  const logger = useLogger()
  const create = useCreateFunction()
  const patch = usePatchFunction()
  const { triggerNotification } = useGlobalToast()
  const { data: creationMetadata } = useGetCreationMetadata()

  const initFunctionsGetParams = (): Parameters<typeof useGetFunctions>[0] => ({
    cursor: undefined,
    limit: 12,
    query: undefined
  })
  const functionsGetReq = useGetFunctions(initFunctionsGetParams())
  const functions = computed(() => functionsGetReq.data.value)

  const reset = async () => {
    await functionsGetReq.reset({ query: initFunctionsGetParams() })
  }

  return {
    creationMetadata,
    functions,
    reset,
    loadMoreFunctions: async () =>
      functionsGetReq.loadMore((oldOpts) => ({
        query: {
          ...oldOpts.query,
          cursor: functions.value?.cursor || undefined
        }
      })),
    /**
     * Update/patch an existing function
     */
    patchFunction: async (...args: Parameters<typeof patch>) => {
      try {
        const res = await patch(...args)
        triggerNotification({
          type: ToastNotificationType.Success,
          title: 'Function updated'
        })

        void reset()

        return res
      } catch (e) {
        logger.error(e)
        triggerNotification({
          type: ToastNotificationType.Danger,
          title: 'Function update failed',
          description: resolveErrMessage(e)
        })
        throw e
      }
    },

    /**
     * Create a new function
     */
    createFunction: async (...args: Parameters<typeof create>) => {
      try {
        const res = await create(...args)
        triggerNotification({
          type: ToastNotificationType.Success,
          title: 'New function created'
        })

        void reset()

        return res
      } catch (e) {
        logger.error(e)
        triggerNotification({
          type: ToastNotificationType.Danger,
          title: 'Function creation failed',
          description: resolveErrMessage(e)
        })
        throw e
      }
    },
    /**
     * Ensure state is fully initialized
     */
    ensure: async () => {
      await functionsGetReq.promise.catch(logger.error)
    }
  }
})
