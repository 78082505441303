/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-invalid-void-type */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FetchResult } from '#app'
import { ensureError, isUndefinedOrVoid, type Optional } from '@speckle/shared'
import { watchTriggerable } from '@vueuse/core'
import type { Dayjs } from 'dayjs'
import { has, isFunction } from 'lodash-es'
import type { AvailableRouterMethod, NitroFetchRequest } from 'nitropack'
import { FetchError } from 'ofetch'
import type { GetAutomationRunsItem } from '~/lib/frontend/automations/composables/management'
import type { EventBusKeyPayloadMap } from '~/lib/frontend/core/composables/eventBus'
import {
  EventBusKeys,
  useEventBusEvent
} from '~/lib/frontend/core/composables/eventBus'

export const isFetchError = (
  e: unknown
): e is FetchError<{ statusMessage: string; message: string }> =>
  e instanceof Error && (has(e, 'statusCode') || has(e, 'statusMessage'))

type PickFrom<T, K extends Array<string>> =
  T extends Array<any>
    ? T
    : T extends Record<string, any>
      ? keyof T extends K[number]
        ? T
        : K[number] extends never
          ? T
          : Pick<T, K[number]>
      : T

type KeysOf<T> = Array<T extends T ? (keyof T extends string ? keyof T : never) : never>

export type UsePagedFetchOptions<
  Q extends Record<string, unknown> = Record<string, unknown>
> = {
  query: Q
}

type PagedFetchResponse<T = void> =
  T extends Record<string, any>
    ? {
        [k in keyof T]: T[k] extends Date | Dayjs
          ? string
          : T[k] extends Optional<Date | Dayjs>
            ? Optional<string>
            : PagedFetchResponse<T[k]>
      }
    : T

type z = PagedFetchResponse<GetAutomationRunsItem>
const a = 1 as unknown as z
a.statusUpdatedAt

/**
 * useFetch with built-in pagination support
 */
export function usePagedFetch<
  ResT = void,
  ReqOptions extends UsePagedFetchOptions = UsePagedFetchOptions,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends AvailableRouterMethod<ReqT> = ResT extends void
    ? 'get' extends AvailableRouterMethod<ReqT>
      ? 'get'
      : AvailableRouterMethod<ReqT>
    : AvailableRouterMethod<ReqT>,
  ErrorT = FetchError,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  DefaultT = null
>(
  request: ReqT | Ref<ReqT>,
  settings: {
    key?: string

    /**
     * (Initial) request options (e.g. query params)
     */
    req: ReqOptions
    /**
     * Merge function for merging pages of results
     */
    resultsMergeFn: (
      newData:
        | NonNullable<DefaultT>
        | PickFrom<PagedFetchResponse<DataT>, KeysOf<PagedFetchResponse<DataT>>>,
      oldData:
        | DefaultT
        | PickFrom<PagedFetchResponse<DataT>, KeysOf<PagedFetchResponse<DataT>>>
        | undefined,
      reqOptions: ReqOptions
    ) => {
      results:
        | DefaultT
        | PickFrom<PagedFetchResponse<DataT>, KeysOf<PagedFetchResponse<DataT>>>
      hasMorePages: boolean
    }
    /**
     * Optionally track event bus events and update fetch data accordingly
     */
    eventBusUpdateHandlers?: {
      [k in EventBusKeys]?: (
        payload: EventBusKeyPayloadMap[k],
        currentData:
          | DefaultT
          | PickFrom<PagedFetchResponse<DataT>, KeysOf<PagedFetchResponse<DataT>>>
          | undefined
      ) =>
        | void
        | DefaultT
        | PickFrom<PagedFetchResponse<DataT>, KeysOf<PagedFetchResponse<DataT>>>
    }
  }
) {
  // Type complexity here is pretty crazy, but it's necessary to get it to work AND
  // to workaround useFetch auto type inference randomly breaking every once in a while...
  type AdjustedResT = PagedFetchResponse<ResT>
  type AdjustedUnderscoreResT = PagedFetchResponse<_ResT>
  type AdjustedDataT = PagedFetchResponse<DataT>
  type AdjustedPickKeys = KeysOf<PagedFetchResponse<DataT>>

  const {
    req,
    resultsMergeFn,
    key = `${JSON.stringify(unref(request))}-${JSON.stringify(settings.req)}`
  } = settings
  const options = ref(req) as Ref<ReqOptions>

  // TS type analysis stack depth exceeded sometimes (randomly)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
  // @ts-ignore
  const asyncData = useFetch<
    AdjustedResT,
    ErrorT,
    ReqT,
    Method,
    AdjustedUnderscoreResT,
    AdjustedDataT,
    AdjustedPickKeys,
    DefaultT
  >(request, {
    query: computed(() => options.value.query),
    ...(key ? { key } : {})
  })
  const finalData = ref<typeof asyncData.data.value>()
  const hasMorePages = ref(true)

  const execAsyncData = async () => {
    // TODO: Do these possibly cause failed duplicate reqs?
    await asyncData.execute()
    if (asyncData.error.value) {
      throw asyncData.error.value
    }
  }

  /**
   * Load next page of results
   */
  const loadMore = async (
    newOpts: typeof req | ((oldOpts: typeof req) => typeof req)
  ) => {
    if (!hasMorePages.value) return false
    options.value = isFunction(newOpts) ? newOpts(options.value) : newOpts
    await execAsyncData()
    return true
  }

  /**
   * Reset internal state and change request options (e.g. when filters change)
   */
  const reset = async (
    newOpts?: typeof req | ((oldOpts: typeof req) => typeof req) | undefined,
    params?: Partial<{
      wipeCurrentData: boolean
    }>
  ) => {
    const { wipeCurrentData = true } = params || {}

    options.value = isFunction(newOpts)
      ? newOpts(options.value)
      : newOpts || options.value

    if (wipeCurrentData) {
      finalData.value = undefined
      hasMorePages.value = true
    }

    await execAsyncData()
    triggerDataWatcher()
  }

  const { trigger: triggerDataWatcher } = watchTriggerable(
    asyncData.data,
    (newData) => {
      if (!newData) return
      const { results, hasMorePages: morePagesExist } = resultsMergeFn(
        newData,
        finalData.value || undefined,
        options.value
      )
      finalData.value = results
      hasMorePages.value = morePagesExist
    },
    { flush: 'sync', immediate: true }
  )

  // Iterate over each event bus update handler and register a watcher
  for (const [event, handler] of Object.entries(
    settings.eventBusUpdateHandlers || {}
  )) {
    useEventBusEvent(event as EventBusKeys, (payload) => {
      const res = handler(payload as any, finalData.value)
      if (isUndefinedOrVoid(res)) return

      finalData.value = res
    })
  }

  return {
    promise: asyncData,
    error: asyncData.error,
    pending: asyncData.pending,
    data: finalData,
    options: computed(() => options.value),
    hasMorePages: computed(() => hasMorePages.value),
    loadMore,
    reset
  }
}

export const useGetApiError =
  () =>
  (err: unknown): FetchError<{ statusMessage: string; message: string }> | Error => {
    if (!isFetchError(err)) return ensureError(err)
    return err
  }

export const useGetApiErrorMessage = () => {
  const getError = useGetApiError()
  return (err: unknown) => {
    const error = getError(err)
    return (
      (isFetchError(error) ? error.data?.message : error.message) ||
      'An unexpected server error occurred'
    )
  }
}

export { FetchError }
