import { default as _91aid_93W9LfDcBrgtMeta } from "/speckle-automate/components/automate-app/src/pages/automations/[aid].vue?macro=true";
import { default as indexZNt2eeiDnGMeta } from "/speckle-automate/components/automate-app/src/pages/automations/index.vue?macro=true";
import { default as _91fid_937ZQeZrm6wMMeta } from "/speckle-automate/components/automate-app/src/pages/functions/[fid].vue?macro=true";
import { default as indexdOL099wj2pMeta } from "/speckle-automate/components/automate-app/src/pages/functions/index.vue?macro=true";
import { default as indexbiVjso03PXMeta } from "/speckle-automate/components/automate-app/src/pages/index.vue?macro=true";
export default [
  {
    name: _91aid_93W9LfDcBrgtMeta?.name ?? "automations-aid",
    path: _91aid_93W9LfDcBrgtMeta?.path ?? "/automations/:aid()",
    meta: _91aid_93W9LfDcBrgtMeta || {},
    alias: _91aid_93W9LfDcBrgtMeta?.alias || [],
    redirect: _91aid_93W9LfDcBrgtMeta?.redirect || undefined,
    component: () => import("/speckle-automate/components/automate-app/src/pages/automations/[aid].vue").then(m => m.default || m)
  },
  {
    name: indexZNt2eeiDnGMeta?.name ?? "automations",
    path: indexZNt2eeiDnGMeta?.path ?? "/automations",
    meta: indexZNt2eeiDnGMeta || {},
    alias: indexZNt2eeiDnGMeta?.alias || [],
    redirect: indexZNt2eeiDnGMeta?.redirect || undefined,
    component: () => import("/speckle-automate/components/automate-app/src/pages/automations/index.vue").then(m => m.default || m)
  },
  {
    name: _91fid_937ZQeZrm6wMMeta?.name ?? "functions-fid",
    path: _91fid_937ZQeZrm6wMMeta?.path ?? "/functions/:fid()",
    meta: _91fid_937ZQeZrm6wMMeta || {},
    alias: _91fid_937ZQeZrm6wMMeta?.alias || [],
    redirect: _91fid_937ZQeZrm6wMMeta?.redirect || undefined,
    component: () => import("/speckle-automate/components/automate-app/src/pages/functions/[fid].vue").then(m => m.default || m)
  },
  {
    name: indexdOL099wj2pMeta?.name ?? "functions",
    path: indexdOL099wj2pMeta?.path ?? "/functions",
    meta: indexdOL099wj2pMeta || {},
    alias: indexdOL099wj2pMeta?.alias || [],
    redirect: indexdOL099wj2pMeta?.redirect || undefined,
    component: () => import("/speckle-automate/components/automate-app/src/pages/functions/index.vue").then(m => m.default || m)
  },
  {
    name: indexbiVjso03PXMeta?.name ?? "index",
    path: indexbiVjso03PXMeta?.path ?? "/",
    meta: indexbiVjso03PXMeta || {},
    alias: indexbiVjso03PXMeta?.alias || [],
    redirect: indexbiVjso03PXMeta?.redirect || undefined,
    component: () => import("/speckle-automate/components/automate-app/src/pages/index.vue").then(m => m.default || m)
  }
]