import { SafeLocalStorage, type Optional } from '@speckle/shared'
import cryptoRandomString from 'crypto-random-string'
import { homeRoute } from '~/lib/common/core/helpers/route'
import { useAuthCookie } from '~/lib/frontend/auth/composables/user'
import { useAutomationsStore } from '~/lib/frontend/automations/stores/automations'
import {
  ToastNotificationType,
  useGlobalToast
} from '~/lib/frontend/common/composables/toast'
import { LocalStorageKeys } from '~/lib/frontend/common/helpers/constants'
import { resolveErrorMessage } from '~/lib/frontend/common/helpers/errors'
import { useCoreStore } from '~/lib/frontend/core/stores/core'
import { useFunctionsStore } from '~/lib/frontend/functions/stores/functions'

const useAuthChallenge = () => {
  const challenge = ref<string>()

  const clear = () => {
    challenge.value = undefined
    SafeLocalStorage.remove(LocalStorageKeys.AuthChallenge)
  }

  const init = () => {
    const newChallenge = cryptoRandomString({ length: 22 })
    SafeLocalStorage.set(LocalStorageKeys.AuthChallenge, newChallenge)
    challenge.value = newChallenge
  }

  // Initially read from localStorage
  if (process.client) {
    challenge.value = SafeLocalStorage.get(LocalStorageKeys.AuthChallenge) || undefined
  }

  return { challenge, clear, init }
}

export function useTriggerLogin() {
  const { challenge, init } = useAuthChallenge()

  return () => {
    if (process.server) {
      throw new Error('Attempting to trigger login from SSR')
    }

    init()
    const url = new URL('/authn/login', window.location.origin)
    url.searchParams.append('challenge', challenge.value || '')

    window.location.href = url.toString()
  }
}

export async function watchForAccessCode() {
  // challenge is stored in localStorage, so this can only run in CSR
  if (process.server) return

  const route = useRoute()
  const router = useRouter()
  const { triggerNotification } = useGlobalToast()
  const { challenge } = useAuthChallenge()
  const authCookie = useAuthCookie()
  const reset = await usePostAuthReset()

  const getTokenWithAccessCode = async (accessCode: string) => {
    const tokenResponse = await $fetch('/api/v1/auth/token', {
      method: 'POST',
      body: JSON.stringify({
        appId: 'automatapp',
        appSecret: 'automatapp',
        accessCode,
        challenge: challenge.value
      })
    })
    return tokenResponse
  }

  watch(
    () => route.query['access_code'] as Optional<string>,
    async (newVal, oldVal) => {
      if (newVal && newVal !== oldVal) {
        try {
          const tokens = await getTokenWithAccessCode(newVal)

          // Set new auth state
          authCookie.value = tokens.token
          await reset({ sendHome: false })

          triggerNotification({
            type: ToastNotificationType.Success,
            title: 'Welcome!',
            description: "You've been successfully authenticated"
          })
        } catch (e) {
          triggerNotification({
            type: ToastNotificationType.Danger,
            title: 'Authentication failed',
            description: resolveErrorMessage(e)
          })
        } finally {
          // Go home
          await router.push({ path: homeRoute, query: {} })
        }
      }
    },
    { immediate: true, flush: 'sync' }
  )
}

export async function usePostAuthReset() {
  const router = useRouter()
  const { clear: clearChallenge } = useAuthChallenge()
  const { reset: sessionReset } = await useSession()
  const core = useCoreStore()
  const automations = useAutomationsStore()
  const functions = useFunctionsStore()

  return async (options?: Partial<{ sendHome: boolean }>) => {
    const { sendHome = true } = options || {}

    if (sendHome) {
      await router.push({ path: homeRoute, query: {} })
    }

    clearChallenge()
    await sessionReset()
    await Promise.all([core.reset(), automations.reset(), functions.reset()])
  }
}

export async function useTriggerLogout() {
  const authCookie = useAuthCookie()
  const reset = await usePostAuthReset()

  return async () => {
    authCookie.value = undefined
    await reset()
  }
}
