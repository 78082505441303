import {
  useCreateAutomation,
  useCreateAutomationVersion,
  useEditAutomation,
  useGetAutomations,
  useGetPublicKeysAutomation,
  useTriggerAutomation
} from '~/lib/frontend/automations/composables/management'
import { useGetApiErrorMessage } from '~/lib/frontend/common/composables/fetch'
import { useLogger } from '~/lib/frontend/common/composables/logger'
import {
  ToastNotificationType,
  useGlobalToast
} from '~/lib/frontend/common/composables/toast'
import type { AutomationPatchRequestBody } from '~/server/api/v1/automations/[automationId]/index.patch'

export const useAutomationsStore = defineStore('automations', () => {
  const create = useCreateAutomation()
  const edit = useEditAutomation()
  const trigger = useTriggerAutomation()
  const getPublicKeys = useGetPublicKeysAutomation()
  const createVersion = useCreateAutomationVersion()
  const { triggerNotification } = useGlobalToast()
  const resolveErrMessage = useGetApiErrorMessage()
  const logger = useLogger()

  const initAutomationsGetParams = (): Parameters<typeof useGetAutomations>[0] => ({
    cursor: undefined,
    limit: 20,
    query: undefined
  })
  const automationsGetReq = useGetAutomations(initAutomationsGetParams())
  const automations = computed(() => automationsGetReq.data.value)
  const resetAutomations = () =>
    automationsGetReq.reset({ query: initAutomationsGetParams() })

  const editAutomation = async (aid: string, params: AutomationPatchRequestBody) => {
    try {
      const res = await edit(aid, params)
      triggerNotification({
        type: ToastNotificationType.Success,
        title: 'Automation updated'
      })

      // Trigger automations re-fetch
      void resetAutomations()

      return res
    } catch (e) {
      triggerNotification({
        type: ToastNotificationType.Danger,
        title: 'Automation update failed',
        description: resolveErrMessage(e)
      })
      throw e
    }
  }

  return {
    automations,
    reset: resetAutomations,
    triggerAutomationRun: async (aid: string, avId: string) => {
      try {
        const res = await trigger(aid, avId)
        triggerNotification({
          type: ToastNotificationType.Success,
          title: 'Automation triggered'
        })
        return res
      } catch (e) {
        triggerNotification({
          type: ToastNotificationType.Danger,
          title: "Automation couldn't be triggered",
          description: resolveErrMessage(e)
        })
        throw e
      }
    },
    getPublicKeysAutomation: async (aid: string) => {
      return await getPublicKeys(aid)
    },
    editAutomation,
    enableAutomation: async (aid: string, shouldBeEnabled = true) =>
      await editAutomation(aid, { enabled: shouldBeEnabled }),
    /**
     * Create a new automation
     */
    createAutomation: async (...args: Parameters<typeof create>) => {
      try {
        const res = await create(...args)
        triggerNotification({
          type: ToastNotificationType.Success,
          title: 'New automation created'
        })

        // Trigger automations re-fetch
        void resetAutomations()

        return res
      } catch (e) {
        triggerNotification({
          type: ToastNotificationType.Danger,
          title: 'Automation creation failed',
          description: resolveErrMessage(e)
        })
        throw e
      }
    },
    /**
     * Create a new automation version
     */
    createAutomationVersion: async (...args: Parameters<typeof createVersion>) => {
      try {
        const res = await createVersion(...args)
        triggerNotification({
          type: ToastNotificationType.Success,
          title: 'New automation version created'
        })

        // Trigger automations re-fetch
        void resetAutomations()

        return res
      } catch (e) {
        triggerNotification({
          type: ToastNotificationType.Danger,
          title: 'Automation version creation failed',
          description: resolveErrMessage(e)
        })
        throw e
      }
    },
    /**
     * Ensure state is fully initialized
     */
    ensure: async () => {
      await automationsGetReq.promise.catch(logger.error)
    }
  }
})
