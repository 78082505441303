<template>
  <div id="speckle" class="bg-foundation-page text-foreground">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <SingletonManagers />
  </div>
</template>
<script setup lang="ts">
import { homeRoute } from '~/lib/common/core/helpers/route'
import { watchForAccessCode } from '~/lib/frontend/auth/composables/auth'
import { useAutomationsStore } from '~/lib/frontend/automations/stores/automations'
import { useLogger } from '~/lib/frontend/common/composables/logger'
import { useTheme } from '~/lib/frontend/core/composables/theme'
import { useCoreStore } from '~/lib/frontend/core/stores/core'
import { useFunctionsStore } from '~/lib/frontend/functions/stores/functions'

const logger = useLogger()
const core = useCoreStore()
const functions = useFunctionsStore()
const automations = useAutomationsStore()
const { isDarkTheme } = useTheme()

useHead({
  // Title suffix
  titleTemplate: (titleChunk) =>
    titleChunk ? `${titleChunk} - Speckle Automate` : 'Speckle Automate',
  htmlAttrs: {
    class: computed(() => (isDarkTheme.value ? `dark` : ``)),
    lang: 'en'
  },
  bodyAttrs: {
    class: 'simple-scrollbar bg-foundation-page text-foreground'
  }
})

useBreadcrumbs(
  [
    {
      title: 'Home',
      to: homeRoute
    }
  ],
  'app-root'
)

await watchForAccessCode()

// awaiting important/global pinia stores
await Promise.all([core.ensure(), functions.ensure(), automations.ensure()])

logger.info('App loaded...')
</script>
<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.1s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
