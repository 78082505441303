import revive_payload_client_YTGlmQWWT5 from "/speckle-automate/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/speckle-automate/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/speckle-automate/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_xKuw1km5sV from "/speckle-automate/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/speckle-automate/components/automate-app/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/speckle-automate/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/speckle-automate/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _001_logger_sQO3GH9dk2 from "/speckle-automate/components/automate-app/src/plugins/001-logger.ts";
import _002_fetchIntercept_LZs1JqudFK from "/speckle-automate/components/automate-app/src/plugins/002-fetchIntercept.ts";
import dayjs_NBCB9zH19f from "/speckle-automate/components/automate-app/src/plugins/dayjs.ts";
import eventBus_nPX20HHw5r from "/speckle-automate/components/automate-app/src/plugins/eventBus.ts";
import portal_WBzLfSYWSd from "/speckle-automate/components/automate-app/src/plugins/portal.ts";
import tippy_NE7EbmCUgf from "/speckle-automate/components/automate-app/src/plugins/tippy.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  chunk_reload_client_SeG0EjL5Ec,
  _001_logger_sQO3GH9dk2,
  _002_fetchIntercept_LZs1JqudFK,
  dayjs_NBCB9zH19f,
  eventBus_nPX20HHw5r,
  portal_WBzLfSYWSd,
  tippy_NE7EbmCUgf
]