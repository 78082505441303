<template>
  <ClientOnly>
    <GlobalToastRenderer v-model:notification="notification" />
  </ClientOnly>
</template>
<script setup lang="ts">
import { GlobalToastRenderer } from '@speckle/ui-components'
import { useGlobalToastManager } from '~/lib/frontend/common/composables/toast'

const { currentNotification, dismiss } = useGlobalToastManager()

const notification = computed({
  get: () => currentNotification.value,
  set: (newVal) => {
    if (!newVal) {
      dismiss()
    }
  }
})
</script>
