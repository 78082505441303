import type { MaybeRef } from '@vueuse/core'
import dayjs from 'dayjs'
import { sortBy, uniqBy } from 'lodash-es'
import {
  usePagedFetch,
  type UsePagedFetchOptions
} from '~/lib/frontend/common/composables/fetch'
import {
  ToastNotificationType,
  useGlobalToast
} from '~/lib/frontend/common/composables/toast'
import { EventBusKeys, useEventBus } from '~/lib/frontend/core/composables/eventBus'
import type {
  RunsGetRequestQuery as VersionRunsGetRequestQuery,
  RunsGetResponseBody as VersionRunsGetResponseBody
} from '~/server/api/v1/automations/[automationId]/[automationVersionId]/runs/index.get'
import type {
  AutomationGetRequestQuery,
  AutomationGetResponseBody
} from '~/server/api/v1/automations/[automationId]/index.get'
import type { AutomationPatchRequestBody } from '~/server/api/v1/automations/[automationId]/index.patch'
import type {
  RunsGetRequestQuery,
  RunsGetResponseBody
} from '~/server/api/v1/automations/[automationId]/runs/index.get'
import type { AutomationRevisionRequestBody } from '~/server/api/v1/automations/[automationId]/versions/index.post'
import type {
  AutomationsGetRequestQuery,
  AutomationsGetResponseBody
} from '~/server/api/v1/automations/index.get'
import type { AutomationsPostRequestBody } from '~/server/api/v1/automations/index.post'
import type { PublicKey } from '~/utils/encryption'

export const useCreateAutomation = () => async (payload: AutomationsPostRequestBody) =>
  $fetch('/api/v1/automations', {
    method: 'POST',
    body: payload
  })

export const useCreateAutomationVersion =
  () => async (automationId: string, payload: AutomationRevisionRequestBody) =>
    $fetch(`/api/v1/automations/${automationId}/versions`, {
      method: 'POST',
      body: payload
    })

export const useGetAutomations = (params: AutomationsGetRequestQuery) =>
  usePagedFetch<
    AutomationsGetResponseBody,
    UsePagedFetchOptions<AutomationsGetRequestQuery>
  >('/api/v1/automations', {
    req: {
      query: params
    },
    resultsMergeFn: (newData, oldData, options) => {
      const results = {
        ...newData,
        items: uniqBy(
          [...(oldData?.items || []), ...newData.items],
          (i) => i.automationId
        )
      }

      const hasMorePages = !!results.cursor && results.cursor !== options.query.cursor

      return {
        results,
        hasMorePages
      }
    }
  })

export type GetAutomationsItem = NonNullable<
  ReturnType<typeof useGetAutomations>['data']['value']
>['items'][0]

export type GetAutomationsVersionItem = GetAutomationsItem['automationVersions'][0]
export type GetAutomationsVersionFunctionItem =
  GetAutomationsVersionItem['functions'][0]

export const useEditAutomation = () => {
  const eventBus = useEventBus()
  return async (aid: string, params: AutomationPatchRequestBody) => {
    const res = await $fetch.raw(<const>`/api/v1/automations/${aid}`, {
      method: 'PATCH',
      body: params
    })

    if (res.status !== 204) {
      throw new Error(`${res.status} ${res.statusText}`)
    }

    eventBus.emit(EventBusKeys.AutomationUpdated, {
      ...params,
      id: aid
    })

    return true
  }
}

export const useTriggerAutomation = () => async (aid: string, avId: string) => {
  const res = await $fetch.raw(`/api/v1/automations/${aid}/${avId}/trigger`, {
    method: 'POST'
  })

  if (res.status === 201) return true
  throw new Error(`${res.status} ${res.statusText}`)
}

export const useGetPublicKeysAutomation = () => async (aid: string) => {
  const keys = await $fetch(`/api/v1/automations/${aid}/keys`)
  return keys as unknown as PublicKey[]
}

export const useGetAutomation = (
  aid: MaybeRef<string>,
  params?: { query?: AutomationGetRequestQuery }
) =>
  usePagedFetch<
    AutomationGetResponseBody,
    UsePagedFetchOptions<AutomationGetRequestQuery>
  >(
    computed(() => `/api/v1/automations/${unref(aid)}` as const),
    {
      req: { query: params?.query || {} },
      resultsMergeFn(newData, oldData, reqOptions) {
        const results = {
          ...newData,
          automationVersions: uniqBy(
            [...(oldData?.automationVersions || []), ...newData.automationVersions],
            (i) => i.automationVersionId
          )
        }

        const hasMorePages =
          !!results.versionCursor && results.versionCursor !== reqOptions.query.cursor

        return {
          results,
          hasMorePages
        }
      },
      eventBusUpdateHandlers: {
        [EventBusKeys.AutomationUpdated]: (payload, currentData) => {
          if (currentData?.automationId !== payload.id) return
          return {
            ...currentData,
            ...payload
          }
        }
      }
    }
  )

export type GetAutomationItem = NonNullable<
  ReturnType<typeof useGetAutomation>['data']['value']
>

export const useGetAutomationRuns = (
  ids: { aId: MaybeRef<string>; avId?: MaybeRef<string | undefined> },
  params?: { query?: RunsGetRequestQuery }
) =>
  usePagedFetch<
    RunsGetResponseBody | VersionRunsGetResponseBody,
    UsePagedFetchOptions<RunsGetRequestQuery | VersionRunsGetRequestQuery>
  >(
    computed(() =>
      unref(ids.avId)
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (`/api/v1/automations/${unref(ids.aId)}/${unref(ids.avId)!}/runs` as const)
        : (`/api/v1/automations/${unref(ids.aId)}/runs` as const)
    ),
    {
      req: { query: params?.query || {} },
      resultsMergeFn(newData, oldData, reqOptions) {
        const results = {
          ...newData,
          items: sortBy(
            uniqBy([...newData.items, ...(oldData?.items || [])], (i) => i.runId),
            (i) => dayjs(i.createdAt).unix() * -1
          )
        }

        const hasMorePages =
          !!results.cursor && results.cursor !== reqOptions.query.cursor

        return {
          results,
          hasMorePages
        }
      }
    }
  )

export type GetAutomationRunsItem = NonNullable<
  ReturnType<typeof useGetAutomationRuns>['data']['value']
>['items'][0]

export const useGetAutomationRunLogs = (
  aid: MaybeRef<string>,
  runId: MaybeRef<string>
) => {
  const { triggerNotification } = useGlobalToast()
  const loading = ref(false)
  const results = ref('')
  const isStreamFinished = ref(false)

  const url = computed(
    () => `/api/v1/automations/${unref(aid)}/runs/${unref(runId)}/logs` as const
  )
  const key = computed(() => `automation-run-logs-${unref(aid)}-${unref(runId)}`)

  const load = async () => {
    const res = await fetch(url.value)

    results.value = ''
    isStreamFinished.value = false

    if (res.status !== 200) {
      // Something bad happened
      const json = (await res.json()) as { message?: string }
      triggerNotification({
        type: ToastNotificationType.Danger,
        title: "Couldn't load logs",
        description: json.message || 'Something went wrong while loading the logs.'
      })
      isStreamFinished.value = true

      return false
    }

    const stream = res.body

    // Read stream into results ref
    if (stream) {
      const reader = stream.getReader()
      const decoder = new TextDecoder()
      const pump = async () => {
        return reader.read().then(({ done, value }): Promise<void> => {
          if (done) {
            isStreamFinished.value = true
            return Promise.resolve()
          }
          results.value += decoder.decode(value)
          return pump()
        })
      }

      // Intentionally not awaiting this so that we can return the ref
      void pump()
    }

    return true
  }

  const loadAndMarkLoading = async () => {
    loading.value = true
    await load().finally(() => {
      loading.value = false
    })
  }

  watch(
    key,
    () => {
      void loadAndMarkLoading()
    },
    { immediate: true }
  )

  return {
    data: computed(() => results.value),
    isStreamFinished: computed(() => isStreamFinished.value),
    loading: computed(() => loading.value)
  }
}
