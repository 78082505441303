import type { Optional } from '@speckle/shared'
import type { MaybeRef } from '@vueuse/core'
import { uniqBy } from 'lodash-es'

export const breadcrumbKey = ({ to, title }: BreadcrumbsItem) => `${to}:::${title}`

export type BreadcrumbsItem = {
  title: string
  to: string
}

export type UseBreadcrumbsState = {
  /**
   * Its an array so that breadcrumbs are sorted by order of creation
   */
  componentItems: {
    key: string
    items: MaybeRef<BreadcrumbsItem>[]
  }[]
}

const useBreadcrumbsState = () =>
  useState<Optional<UseBreadcrumbsState>>('app-breadcrumbs-state')

/**
 * Set page breadcrumbs
 * Note: Only supported within pages for now!
 */
export const useBreadcrumbs = (items: MaybeRef<BreadcrumbsItem>[], key: string) => {
  const state = useBreadcrumbsState()

  // Set/replace items
  const componentItems = state.value?.componentItems || []
  const currentItemsIdx = componentItems.findIndex((i) => i.key === key)
  if (currentItemsIdx !== -1) {
    componentItems[currentItemsIdx] = { key, items }
  } else {
    componentItems.push({ key, items })
  }

  state.value = {
    ...(state.value || {}),
    componentItems
  }

  onBeforeUnmount(() => {
    // Remove on unmount
    const newItems = (state.value?.componentItems || []).filter((i) => i.key !== key)

    state.value = {
      ...(state.value || {}),
      componentItems: newItems
    }
  })
}

/**
 * Get current page breadcrumbs
 */
export const useCurrentBreadcrumbsState = () => {
  const state = useBreadcrumbsState()

  const allItems = computed(() => {
    const items = state.value?.componentItems || []
    return uniqBy(items.flatMap((i) => i.items).map(unref), breadcrumbKey)
  })

  return {
    allItems
  }
}
