import { isNumber, isObjectLike as lodashIsObjectLike } from 'lodash-es'

export type NotRef<T> = T extends Ref<unknown> ? never : T

type NumericEnum = Record<string, number | string>
type StringEnum = Record<string, string>

/**
 * Get keys and values of a numeric enum
 * @param enumerator Enum with numeric values
 */
export function getNumericEnumKeysValues(enumerator: NumericEnum): {
  keys: string[]
  values: number[]
} {
  const keys = Object.keys(enumerator).filter((k) => isNumber(enumerator[k]))
  const values = keys.map((k) => enumerator[k] as number)
  return { keys, values }
}

/**
 * Get keys of a numeric enum
 * @param enumerator Enum with numeric values
 */
export const getNumericEnumKeys = (enumerator: NumericEnum): string[] =>
  getNumericEnumKeysValues(enumerator).keys

/**
 * Get values of a numeric enum
 * @param enumerator Enum with numeric values
 */
export const getNumericEnumValues = (enumerator: NumericEnum): number[] =>
  getNumericEnumKeysValues(enumerator).values

/**
 * Get keys and values of a string enum
 * @param enumerator Enum with string values
 */
export function getStringEnumKeysValues(enumerator: StringEnum): {
  keys: string[]
  values: string[]
} {
  return {
    keys: Object.keys(enumerator),
    values: Object.values(enumerator)
  }
}

export const isObjectLike = (value: unknown): value is Record<string, unknown> =>
  lodashIsObjectLike(value)
