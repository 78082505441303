import { until } from '@vueuse/core'
import { useActiveUserData, useAuthCookie } from '~/lib/frontend/auth/composables/user'
import { useLogger } from '~/lib/frontend/common/composables/logger'
import { useScopedState } from '~/lib/frontend/common/composables/scopedState'
import {
  SpeckleServerType,
  useGetServerType
} from '~/lib/frontend/core/composables/server'

export const useActiveUser = () =>
  useScopedState('coreActiveUser', () => {
    const activeUserReq = useActiveUserData()
    const {
      data: activeUserData,
      pending: activeUserPending,
      refresh: refreshActiveUser
    } = activeUserReq
    const activeUser = computed(() =>
      activeUserPending.value ? undefined : activeUserData.value
    )

    return {
      activeUser,
      refreshActiveUser,
      activeUserReq
    }
  })

export const useCoreStore = defineStore('core', () => {
  const logger = useLogger()
  const activeUserState = useActiveUser()
  const activeUser = computed(() => activeUserState.activeUser.value)
  const serverType = useGetServerType()
  const isFrontend2 = computed(() => serverType.value === SpeckleServerType.Frontend2)

  const authCookie = useAuthCookie()
  const isLoggedIn = computed(() => !!(authCookie.value && activeUser.value))

  return {
    activeUser,
    authCookie,
    isLoggedIn,
    isFrontend2,
    /**
     * Should be invoked when auth state changes
     */
    reset: async () => {
      await activeUserState.refreshActiveUser()
    },
    /**
     * Ensure state is fully initialized
     */
    ensure: async () => {
      await Promise.all([
        activeUserState.activeUserReq,
        until(serverType).toMatch((val) => !!val)
      ]).catch(logger.error)
    }
  }
})
