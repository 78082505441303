import { isString } from 'lodash-es'
import { REQUEST_ID_HEADER } from '~/lib/common/core/helpers/server'
import { useRequestId } from '~/lib/frontend/core/composables/server'

const ghRegex = /\.github(usercontent)?\.com/i

/**
 * Setting up a global fetch interceptor so that we can add some extra headers to every request
 */
export default defineNuxtPlugin(() => {
  const reqId = useRequestId()
  const ofetch = globalThis.$fetch
  globalThis.$fetch = ofetch.create({
    onRequest: ({ request, options }) => {
      const url = isString(request) ? request : request.url

      // Skip if requesting github, these extra headers cause these reqs to fail for some reason
      if (ghRegex.test(url)) return

      options.headers = {
        ...(options.headers || {}),
        [REQUEST_ID_HEADER]: reqId,
        'x-fetch-intercepted': 'true'
      }
    }
  })
})
