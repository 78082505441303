import type { Optional } from '@speckle/shared'
import type { H3Event } from 'h3'
import { nanoid } from 'nanoid'
import { useLogger } from '~/lib/frontend/common/composables/logger'

export enum SpeckleServerType {
  Frontend1 = 'fe1',
  Frontend2 = 'fe2'
}

/**
 * We cache the server type to avoid making a request every time we need it. We're
 * also OK with this being shared across SSR requests.
 */
let cachedServerType: Optional<SpeckleServerType> = undefined

export const useGetServerType = () => {
  const {
    public: { speckleServerUrl }
  } = useRuntimeConfig()
  const logger = useLogger()

  const state = useState('speckle-server-type', () => ({
    type: cachedServerType
  }))

  const type = computed(() => {
    return state.value.type
  })
  if (type.value) return type

  // Load value for the first time
  $fetch
    .raw(speckleServerUrl, { mode: 'no-cors' })
    .then((res) => {
      const isFe2 = res.headers.get('x-speckle-frontend-2') === 'true'
      cachedServerType = state.value.type = isFe2
        ? SpeckleServerType.Frontend2
        : SpeckleServerType.Frontend1
    })
    .catch((err) => {
      logger.error(err)
      cachedServerType = state.value.type = SpeckleServerType.Frontend1
    })
    .finally(() => {
      triggerRef(type)
    })

  return type
}

/**
 * Get the req.id that is/was used in the initial SSR request
 *
 * Note: In SSR, this can only be used after the 001-logging middleware, cause that's when the ID is set
 */
export function useServerRequestId() {
  const nuxt = useNuxtApp()
  const state = useState('server_request_id', () => {
    // The client side should not need to resolve this info, as it should come from the serialized SSR state
    if (process.client || !nuxt.ssrContext) return undefined
    return nuxt.ssrContext.event.node.req.id as string
  })

  return computed(() => state.value)
}

/**
 * Get the value that should be used as the request correlation ID
 *
 * Note: In SSR, this can only be used after the 001-logging middleware, cause that's when the ID is set, otherwise
 * we fallback to a new nanoid
 */
export function useRequestId(params?: {
  /**
   * Specify, if invoking composable from within server-side event handler, cause we don't have access to useNuxtApp()
   * or anything of the like there
   */
  event?: H3Event
}) {
  let id = nanoid()
  if (process.server) {
    id = (params?.event || useNuxtApp().ssrContext?.event)?.node.req.id as string
    if (!id) {
      throw new Error("Couldn't determine request ID")
    }

    return id
  } else {
    // We retain it in the state so that all reqs going out from the same client-side app session
    // have the same id
    const state = useState('app_request_correlation_id', () => id)
    return state.value
  }
}

/**
 * Resolve the user's geolocation, if possible (only supported wherever we host behind Cloudflare)
 */
export function useUserCountry() {
  const nuxt = useNuxtApp()
  const state = useState('active_user_country', () => {
    // The client side should not need to resolve this info, as it should come from the serialized SSR state
    if (process.client || !nuxt.ssrContext) return undefined
    return nuxt.ssrContext.event.node.req.headers['cf-ipcountry'] as Optional<string>
  })

  return computed(() => state.value)
}
