import { getCurrentInstance } from 'vue'
import type { usePatchFunction } from '~/lib/frontend/functions/composables/management'
import type { AutomationPatchRequestBody } from '~/server/api/v1/automations/[automationId]/index.patch'

export enum EventBusKeys {
  FunctionUpdated = 'function-updated',
  AutomationUpdated = 'automation-updated',
  TestEvent = 'test-event'
}

// Add mappings between event keys and expected payloads here
export type EventBusKeyPayloadMap = {
  [EventBusKeys.FunctionUpdated]: Awaited<
    ReturnType<ReturnType<typeof usePatchFunction>>
  >
  [EventBusKeys.AutomationUpdated]: AutomationPatchRequestBody & { id: string }
  [EventBusKeys.TestEvent]: { foo: string }
} & { [k in EventBusKeys]: unknown } & Record<string, unknown>

/**
 * Warning: For listening to events use `useEventBusEvent` instead, cause that will
 * handle the lifecycle of the event listener for you.
 */
export function useEventBus() {
  const nuxt = useNuxtApp()
  return nuxt.$eventBus
}

export function useEventBusEvent<K extends EventBusKeys>(
  key: K,
  handler: (event: EventBusKeyPayloadMap[K]) => void
) {
  const eventBus = useEventBus()

  eventBus.on(key, handler)

  if (getCurrentInstance()) {
    onUnmounted(() => eventBus.off(key, handler))
  }

  return () => eventBus.off(key, handler)
}
