import type { MaybeRef } from '@vueuse/core'
import { uniqBy } from 'lodash-es'
import {
  usePagedFetch,
  type UsePagedFetchOptions
} from '~/lib/frontend/common/composables/fetch'
import { EventBusKeys, useEventBus } from '~/lib/frontend/core/composables/eventBus'
import type {
  FunctionGetRequestQuery,
  FunctionGetResponseBody
} from '~/server/api/v1/functions/[functionId]/index.get'
import type { FunctionPatchRequestBody } from '~/server/api/v1/functions/[functionId]/index.patch'
import type { FunctionVersionGetResponseBody } from '~/server/api/v1/functions/[functionId]/versions/[functionVersionId]/index.get'
import type { FunctionCreationMetadataResponse } from '~/server/api/v1/functions/creation.get'
import type { CreateFunctionFromTemplateRequestBody } from '~/server/api/v1/functions/from-template.post'
import type {
  FunctionsGetRequestQuery,
  FunctionsGetResponseBody
} from '~/server/api/v1/functions/index.get'

export const useCreateFunction =
  () => async (payload: CreateFunctionFromTemplateRequestBody) =>
    $fetch('/api/v1/functions/from-template', {
      method: 'POST',
      body: payload
    })

export type CreateFunctionResponse = Awaited<
  ReturnType<ReturnType<typeof useCreateFunction>>
>

export const usePatchFunction = () => {
  const eventBus = useEventBus()

  return async (functionId: string, payload: FunctionPatchRequestBody) =>
    $fetch(`/api/v1/functions/${functionId}` as `/api/v1/functions/:functionId`, {
      method: 'PATCH',
      body: payload
    }).then((res) => {
      eventBus.emit(EventBusKeys.FunctionUpdated, res)
      return res
    })
}

export const useGetFunctions = (params: FunctionsGetRequestQuery, key?: string) =>
  usePagedFetch<
    FunctionsGetResponseBody,
    UsePagedFetchOptions<FunctionsGetRequestQuery>
  >('/api/v1/functions', {
    req: {
      query: params
    },
    resultsMergeFn: (newData, oldData, options) => {
      const results = {
        ...newData,
        items: uniqBy(
          [...(oldData?.items || []), ...newData.items],
          (i) => i.functionId
        )
      }

      const hasMorePages = !!results.cursor && results.cursor !== options.query.cursor

      return {
        results,
        hasMorePages
      }
    },
    ...(key ? { key } : {})
  })

export type GetFunctionsItem = NonNullable<
  ReturnType<typeof useGetFunctions>['data']['value']
>['items'][0]

export type GetFunctionsVersionItem = GetFunctionsItem['functionVersions'][0]

export const useGetFunction = (
  fid: MaybeRef<string>,
  params?: { query?: FunctionGetRequestQuery }
) =>
  usePagedFetch<FunctionGetResponseBody, UsePagedFetchOptions<FunctionGetRequestQuery>>(
    computed(
      () => `/api/v1/functions/${unref(fid)}` as `/api/v1/functions/:functionId`
    ),
    {
      req: { query: params?.query || {} },
      resultsMergeFn(newData, oldData, reqOptions) {
        const results = {
          ...newData,
          functionVersions: uniqBy(
            [...(oldData?.functionVersions || []), ...newData.functionVersions],
            (i) => i.functionVersionId
          )
        }
        newData.createdAt

        const hasMorePages =
          !!results.versionCursor && results.versionCursor !== reqOptions.query.cursor

        return {
          results,
          hasMorePages
        }
      },
      eventBusUpdateHandlers: {
        [EventBusKeys.FunctionUpdated]: (payload, currentData) => {
          if (currentData?.functionId !== payload.functionId) return
          return {
            ...currentData,
            ...payload
          }
        }
      }
    }
  )

export type GetFunctionItem = NonNullable<
  ReturnType<typeof useGetFunction>['data']['value']
>

export const useGetFunctionVersion = (
  params: {
    functionId: MaybeRef<string>
    functionVersionId: MaybeRef<string>
  },
  options?: Partial<{
    manual: boolean
  }>
) =>
  useFetch<FunctionVersionGetResponseBody>(
    computed(
      () =>
        `/api/v1/functions/${unref(params.functionId)}/versions/${unref(
          params.functionVersionId
        )}` as '/api/v1/functions/:functionId/versions/:functionVersionId'
    ),
    { immediate: !options?.manual }
  )

export type GetFunctionVersionItem = NonNullable<
  ReturnType<typeof useGetFunctionVersion>['data']['value']
>

export const useGetCreationMetadata = () =>
  useFetch<FunctionCreationMetadataResponse>('/api/v1/functions/creation')

export type GetCreationMetadata = NonNullable<
  ReturnType<typeof useGetCreationMetadata>['data']['value']
>

export type FunctionTemplateItem = GetCreationMetadata['templateRepositories'][0]
